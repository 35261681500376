<!--begin::Page bg image-->
<style>
.body {
  background: linear-gradient(to right, #25952550, #ffffff00),
    url("/assets/media/auth/bg4.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  height: 100%;
}
[data-bs-theme="dark"] .body {
  background-image: url("/assets/media/auth/bg10-dark.jpeg");
}
</style>
<!--end::Page bg image-->

<template>
  <div class="body">
    <!--begin::Root-->
    <div class="d-flex flex-column flex-root vh-100">
      <!--begin::Authentication - Sign-in -->
      <div class="d-flex flex-column flex-lg-row flex-column-fluid">
        <!--begin::Aside-->
        <div class="d-flex flex-lg-row-fluid">
          <!--begin::Content-->
          <div class="d-flex flex-column flex-center pb-0 pb-lg-10 p-10 w-100">
            <!--begin::Image-->
            <img
              class="theme-light-show mx-auto mw-100 w-150px w-lg-300px mb-10 mb-lg-20"
              src="/assets/media/logos/tni-logo.png"
              alt=""
            />
            <img
              class="theme-dark-show mx-auto mw-100 w-150px w-lg-300px mb-10 mb-lg-20"
              src="/assets/media/logos/tni-logo.png"
              alt=""
            />
            <!--end::Image-->
            <!--begin::Title-->
            <h1 class="text-white fs-2qx fw-bold text-center mb-7">
              Aplikasi Tanda Tangan Digital dan Cetak KTA TNI
            </h1>
            <!--end::Title-->
            <!--begin::Text-->
            <div class="text-warning fs-base text-center fw-semibold">
              Merupakan aplikasi yang difungsikan untuk pengajuan KTA anggota
              TNI, tanda tangan digital hingga melakukan pencetakan KTA.
            </div>
            <!--end::Text-->
          </div>
          <!--end::Content-->
        </div>
        <!--begin::Aside-->
        <!--begin::Body-->
        <div
          class="d-flex flex-column-fluid flex-lg-row-auto justify-content-center justify-content-lg-end pt-12 p-lg-12"
        >
          <!--begin::Wrapper-->
          <div
            class="bg-body d-flex flex-column flex-center rounded-4 w-md-500px p-10 shadow border border-5 border-secondary"
          >
            <!--begin::Content-->
            <div
              class="d-flex flex-center flex-column align-items-stretch h-lg-100 w-md-400px"
            >
              <!--begin::Wrapper-->
              <div class="d-flex flex-center flex-column flex-column-fluid">
                <!--begin::Form-->
                <Form
                  @submit="handleLogin"
                  :validation-schema="schema"
                  class="form w-100"
                  novalidate="novalidate"
                  id="kt_sign_in_form"
                >
                  <!--begin::Heading-->
                  <div class="text-center">
                    <img
                      src="/assets/media/logos/TNI-AD.png"
                      alt=""
                      class="img-fluid w-60px mx-3"
                    />
                    <img
                      src="/assets/media/logos/TNI-AL.png"
                      alt=""
                      class="img-fluid w-70px mx-3"
                    />
                    <img
                      src="/assets/media/logos/TNI-AU.png"
                      alt=""
                      class="img-fluid w-70px mx-3"
                    />
                  </div>
                  <!--begin::Separator-->
                  <div class="separator separator-content my-7">
                    <span
                      class="w-125px text-gray-500 fw-semibold fs-7 text-success"
                    ></span>
                  </div>
                  <!--end::Separator-->
                  <div class="text-center mb-5">
                    <!--begin::Title-->
                    <h1 class="text-dark fw-bolder">MASUK KE APLIKASI</h1>
                    <!--end::Title-->
                    <!--begin::Subtitle-->
                    <!--<div class="text-gray-500 fw-semibold fs-6"></div>-->
                    <!--end::Subtitle=-->
                  </div>
                  <!--begin::Heading-->
                  <!--begin::Input group=-->
                  <div class="fv-row mb-8">
                    <!--begin::Email-->
                    <Field
                      type="email"
                      placeholder="Email"
                      name="email"
                      autocomplete="off"
                      class="form-control bg-transparent"
                    />
                    <ErrorMessage name="username" class="error-feedback" />
                    <!--end::Email-->
                  </div>
                  <!--end::Input group=-->
                  <div class="fv-row mb-3">
                    <!--begin::Password-->
                    <Field
                      type="password"
                      placeholder="Password"
                      name="password"
                      autocomplete="off"
                      class="form-control bg-transparent"
                    />
                    <ErrorMessage name="password" class="error-feedback" />

                    <!--end::Password-->
                  </div>
                  <!--end::Input group=-->
                  <!--begin::Wrapper-->
                  <div
                    class="d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8"
                  >
                    <div></div>
                    <!--begin::Link-->
                    <a href="void::javascript(0);" class="link-success"
                      >Lupa Password ?</a
                    >
                    <!--end::Link-->
                  </div>
                  <!--end::Wrapper-->
                  <!--begin::Submit button-->
                  <div class="d-grid mb-10">
                    <button
                      :disabled="loading"
                      type="submit"
                      id="kt_sign_in_submit"
                      class="btn btn-success"
                    >
                      <!--begin::Indicator label-->
                      <span class="indicator-label">Masuk</span>
                      <!--end::Indicator label-->
                      <!--begin::Indicator progress-->
                      <span class="indicator-progress" v-show="loading">
                        Please wait...
                        <span
                          class="spinner-border spinner-border-sm align-middle ms-2"
                        ></span>
                      </span>
                      <!--end::Indicator progress-->
                    </button>
                  </div>
                  <div v-if="message" class="alert alert-danger" role="alert">
                    {{ message }}
                  </div>

                  <!--end::Submit button-->
                </Form>
                <!--end::Form-->
              </div>
              <!--end::Wrapper-->
            </div>
            <!--end::Content-->
          </div>
          <!--end::Wrapper-->
        </div>
        <!--end::Body-->
      </div>
      <!--end::Authentication - Sign-in-->
    </div>
    <!--end::Root-->
  </div>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";

export default {
  name: "Login",
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    const schema = yup.object().shape({
      email: yup.string().required("Username is required!"),
      password: yup.string().required("Password is required!"),
    });

    return {
      loading: false,
      message: "",
      schema,
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  created() {
    if (this.loggedIn) {
      this.$router.push({ name: "dashboard" });
    }
  },
  methods: {
    handleLogin(user) {
      this.loading = true;
      this.$store.dispatch("auth/login", user).then(
        () => {
          this.$router.push({ name: "dashboard" });
          this.$swal.fire({
            title: "Login!",
            text: "Success...",
            icon: "success",
          });
        },
        (error) => {
          this.loading = false;
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
  },
};
</script>
